import React from 'react';

import Box from '@mui/material/Box';

import { LayoutBase } from '../base';

import { WebNavbarAppBar } from '../../ui/web/navbar/appbar';
import {
  WebNavbarDrawer,
  WebNavbarDrawerHeader,
} from '../../ui/web/navbar/drawer';

import { WebFooter } from '../../ui/web/footer';

export function LayoutWeb({ children }: any) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <LayoutBase>
      <Box sx={{ display: 'flex' }}>
        <WebNavbarAppBar
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
        <WebNavbarDrawer open={drawerOpen} setOpen={setDrawerOpen} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <WebNavbarDrawerHeader variant="dense" />

          {children}

          {/* footer */}
          <WebFooter />
        </Box>
      </Box>
    </LayoutBase>
  );
}
