import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import Logo from '../../../assets/images/logo/icon-text-blue.svg';
import '../../../assets/css/_full-height.css';

import { NavbarActionsAvatar } from '../../ui/navbar/actions/avatar';
import { Impressum } from '../../ui/impressum';

export function ScreenInfoWaitlist() {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 0 }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <NavbarActionsAvatar />
          </Toolbar>
        </AppBar>
      </Box>

      <Container
        maxWidth="xs"
        sx={{
          my: 9,
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <img
            src={Logo}
            alt="logo"
            style={{ maxWidth: '100%', width: '200px' }}
          />
        </Box>
        <Paper variant="outlined" sx={{ py: 5, px: 4.5 }}>
          <Typography variant="body1" paragraph>
            Hi there,
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for your interest in Taxonomy.
          </Typography>

          <Typography variant="body1" paragraph>
            We have added you to the waitlist and will notify you when we launch
            for public access.
          </Typography>

          {/* signature */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Square+Peg&display=swap"
            rel="stylesheet"
          />
          <Typography
            variant="body1"
            sx={{ fontFamily: "'Square Peg', cursive", fontSize: 42 }}
          >
            Taxonomy Team
          </Typography>
          <Typography variant="caption" paragraph>
            <Link href="https://taxonomy.cc/">https://taxonomy.cc/</Link>
          </Typography>

          {/* ps */}
          <Typography variant="body2">
            PS: If you would like early access, please contact us at{' '}
            <Link href="mailto:earlyaccess@taxonomy.cc">
              earlyaccess@taxonomy.cc
            </Link>{' '}
            and we will get back to you as soon as possible.
          </Typography>
        </Paper>

        <Impressum />
      </Container>
    </Box>
  );
}
