import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { AuthLoginIntro } from '../../ui/auth/login-intro';
import { AuthLoginGoogle } from '../../ui/auth/login/google';
import { AuthLoginApple } from '../../ui/auth/login/apple';
import { AuthLoginMicrosoft } from '../../ui/auth/login/microsoft';
import { AuthLoginInputForm } from '../../ui/auth/login/input-form';

import { Impressum } from '../../ui/impressum';

export function ScreenAuthLogin() {
  return (
    <Box>
      <Container maxWidth="xs" sx={{ mt: 12, mb: 8 }}>
        {/* content */}
        <Stack spacing={2}>
          <AuthLoginIntro />
          <Box sx={{ mb: 2 }} />

          <AuthLoginGoogle />
          <AuthLoginApple />
          <AuthLoginMicrosoft />
        </Stack>

        <Divider sx={{ my: 3 }}>OR</Divider>

        {/* email / phone sign in */}
        <AuthLoginInputForm />

        {/* privacy & terms + copyright */}
        <Impressum />
      </Container>
    </Box>
  );
}
