import { useUser } from 'reactfire';
import { getAuth, signOut } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { ImpressumPrivacy } from '../../../../impressum';

export function NavbarActionsAvatarModalContent() {
  const auth = getAuth();
  const { data: user } = useUser();

  const handleSignOut = () => {
    signOut(auth);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar alt={user?.displayName || ''} src={user?.photoURL || ''} />
          </Box>
          <Box>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              {user?.displayName}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'center', color: 'grey.700' }}
            >
              {user?.email || user?.phoneNumber}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Divider />
      <Box sx={{ p: 2 }}>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<ExitToAppIcon />}
          onClick={handleSignOut}
          sx={{ textTransform: 'none' }}
        >
          Sign Out
        </Button>
      </Box>
      <Divider />

      <Box sx={{ m: 2 }}>
        <ImpressumPrivacy />
      </Box>
    </>
  );
}
