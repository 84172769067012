import Popover from '@mui/material/Popover';
import { NavbarActionsAvatarModalContent } from './content';

export function NavbarActionsAvatarModalPopover({
  anchorEl,
  handleClose,
}: any) {
  const open = Boolean(anchorEl);
  const id = open ? 'navbar-actions-avatar-modal-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          width: '300px',
          mt: 1,
        },
      }}
    >
      <NavbarActionsAvatarModalContent />
    </Popover>
  );
}
