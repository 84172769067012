import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';

import { logError } from '../../../_lib/log-error';

export function AuthLoginApple() {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');

  const handleClick = () => {
    signInWithPopup(auth, provider).catch((error) => {
      logError(error);
    });
  };

  return (
    <Box sx={{ color: '#000' }}>
      <Button
        variant="outlined"
        fullWidth
        sx={{ textTransform: 'none' }}
        color="inherit"
        onClick={handleClick}
        startIcon={<AppleIcon />}
      >
        Sign in with Apple
      </Button>
    </Box>
  );
}
