import React from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { logError } from '../../../_lib/log-error';

export function AuthLoginInputForm() {
  const [error, setError] = React.useState<string | null>(null);
  const [emailSent, setEmailSent] = React.useState(false);

  const auth = getAuth();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailSent(false);

    const email = (new FormData(event.currentTarget).get('email') ||
      '') as string;

    if (!email) {
      setError('Please enter an email address.');
      return;
    }

    setError(null);

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.href,
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: 'io.tenery.taxonomy',
      },
      android: {
        packageName: 'io.tenery.taxonomy',
        installApp: true,
        minimumVersion: '12',
      },
      dynamicLinkDomain: 'taxonomy.page.link',
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        setEmailSent(true);
      })
      .catch((err) => {
        logError(err);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ color: '#000' }}>
        <TextField
          type="email"
          fullWidth
          size="small"
          placeholder="Email"
          name="email"
          error={!!error}
          helperText={error}
        />
        <Button
          variant="contained"
          type="submit"
          sx={{ textTransform: 'none', mt: 2, '&:hover': { color: 'white' } }}
          color="primary"
          fullWidth
          disableElevation
        >
          Sign in with Email
        </Button>
      </Box>
      <Alert
        icon={!emailSent ? <AutoAwesomeIcon /> : <CheckCircleOutlineIcon />}
        severity={!emailSent ? 'info' : 'success'}
        sx={{
          textAlign: 'left',
          mt: 2.5,
        }}
      >
        {!emailSent
          ? `We will email you a magic link for a password-free sign in.`
          : `We sent you an email with a magic link for a password-free sign in.`}
      </Alert>
    </form>
  );
}
