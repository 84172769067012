import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IconTextLogo from '../../../assets/images/logo/icon-text-blue.svg';

export function AuthLoginIntro() {
  return (
    <Box>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <img
          src={IconTextLogo}
          alt="icon text logo"
          style={{ maxWidth: '100%', width: '240px' }}
        />
      </Box>
      <Typography variant="body1">
        We suggest using the{' '}
        <strong>email address you use at work or school</strong>.
      </Typography>
    </Box>
  );
}
