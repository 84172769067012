import { KBarProvider, KBarPortal, KBarPositioner, KBarAnimator } from 'kbar';

import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';

import { KbarSearch } from '../../ui/kbar/search';
import { KbarResults } from '../../ui/kbar/results';

function BlurryBackdrop(props: any) {
  const { sx, ...other } = props;
  return (
    <Backdrop
      {...other}
      sx={{
        ...sx,
        bgcolor: 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(4px)',
      }}
    />
  );
}

export function CustomKBarProvider({ children, enabled }: any) {
  if (!enabled) return children;

  return (
    <KBarProvider
      actions={[]}
      options={{
        enableHistory: true,
        disableScrollbarManagement: true,
      }}
    >
      <KBarPortal>
        <KBarPositioner>
          <KBarAnimator>
            <Dialog
              open
              maxWidth="sm"
              fullWidth
              components={{ Backdrop: BlurryBackdrop }}
            >
              <DialogContent>
                <Stack spacing={2}>
                  <KbarSearch />
                  <KbarResults />
                </Stack>
              </DialogContent>
            </Dialog>
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
}

export default CustomKBarProvider;
