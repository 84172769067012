import { Link as RouterLink, Route } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { RedirectRoute } from '../_lib/route-utils';

export function ImpressumRoutes() {
  return [
    <Route
      path="/privacy-policy"
      element={
        <RedirectRoute
          url="https://bit.ly/384ST6f"
          title="Privacy Policy - Taxonomy"
        />
      }
    />,
    <Route
      path="/terms-of-service"
      element={
        <RedirectRoute
          url="https://bit.ly/3jQUcIt "
          title="Terms of Service - Taxonomy"
        />
      }
    />,
  ];
}

export function ImpressumPrivacy({
  color,
  colorHover,
}: {
  color?: string;
  colorHover?: string;
}) {
  return (
    <Typography
      variant="body2"
      color={color}
      align="center"
      sx={{
        '& a': {
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        },
      }}
    >
      <Link
        component={RouterLink}
        to="/privacy-policy"
        target="_blank"
        sx={{
          textDecoration: 'none !important',
          color: 'inherit',
          '&:hover': {
            color: colorHover,
          },
        }}
      >
        Privacy Policy
      </Link>
      {' . '}
      <Link
        component={RouterLink}
        to="/terms-of-service"
        target="_blank"
        sx={{
          textDecoration: 'none !important',
          color: 'inherit',
          '&:hover': {
            color: colorHover,
          },
        }}
      >
        Terms of Service
      </Link>
    </Typography>
  );
}
ImpressumPrivacy.defaultProps = {
  color: 'text.secondary',
  colorHover: 'text.primary',
};

export function ImpressumCopyright({ color }: { color?: string }) {
  return (
    <Typography variant="body2" color={color} align="center">
      © {new Date().getFullYear()} Tenery Research
    </Typography>
  );
}
ImpressumCopyright.defaultProps = {
  color: 'text.secondary',
};

export function Impressum() {
  return (
    <>
      {/* Privacy + Terms + Copyright */}
      <Box sx={{ mt: 5, mb: 3 }}>
        <ImpressumPrivacy />
      </Box>
      <Box mt={2} mb={2}>
        <ImpressumCopyright />
      </Box>
    </>
  );
}
