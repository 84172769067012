import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ImpressumRoutes } from '../../ui/impressum';

import { PrivateRoute, ReversePrivateRoute } from '../../_lib/route-utils';

import { ScreenAuthLogin } from '../auth/login';
import { ScreenInfoNotFound } from '../_info/not-found';
import { ScreenInfoWaitlist } from '../_info/waitlist';

import { ScreenWebHome } from './home';

export function WebRouter() {
  return (
    <Routes>
      {/* privacy + terms  */}
      {ImpressumRoutes().map((route: any) =>
        React.cloneElement(route, { key: route.props.path })
      )}

      {/* routes */}

      <Route
        path="/"
        element={
          <PrivateRoute>
            <ScreenWebHome />
          </PrivateRoute>
        }
      />

      {/* Auth */}
      <Route
        path="/login"
        element={
          <ReversePrivateRoute>
            <ScreenAuthLogin />
          </ReversePrivateRoute>
        }
      />
      <Route
        path="/waitlist"
        element={
          <PrivateRoute>
            <ScreenInfoWaitlist />
          </PrivateRoute>
        }
      />

      {/* 404 */}
      <Route path="*" element={<ScreenInfoNotFound />} />
    </Routes>
  );
}
