import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { DialogTitle } from '../../../../dialog-title';

import { NavbarActionsAvatarModalContent } from './content';

import Logo from '../../../../../../assets/images/logo/icon-text-blue.svg';

export function NavbarActionsAvatarModalDialog({ open, handleClose }: any) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle onClose={handleClose} sx={{ pb: 0 }}>
        <Box sx={{ textAlign: 'center' }}>
          <img src={Logo} style={{ height: '30px' }} alt="logo" />
        </Box>
      </DialogTitle>
      <NavbarActionsAvatarModalContent />
    </Dialog>
  );
}
