import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { ErrorFallback } from '../../_screen/_info/error';
import { FirebaseEffects, FirebaseProvider } from './firebase';
import { ThemeProvider } from './theme';
import { OfflineEffects } from '../offline/effects';
import KBarProvider from './kbar';

export function CommonProviders({ firebaseConfig, enableKbar, children }: any) {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <KBarProvider enabled={enableKbar}>
            <HelmetProvider>
              <BrowserRouter>
                <FirebaseProvider config={firebaseConfig}>
                  {children}
                  <FirebaseEffects />
                  <OfflineEffects />
                </FirebaseProvider>
              </BrowserRouter>
            </HelmetProvider>
          </KBarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </React.Suspense>
  );
}
