import React from 'react';

import { useUser } from 'reactfire';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import { NavbarActionsAvatarModalDialog } from './modal/dialog';
import { NavbarActionsAvatarModalPopover } from './modal/popover';

export function NavbarActionsAvatar({ size }: { size?: number }) {
  const { status, data: user } = useUser();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
  };

  return (
    <>
      {/* modals */}
      {smDown ? (
        <NavbarActionsAvatarModalDialog
          open={openDialog}
          handleClose={handleClose}
        />
      ) : (
        <NavbarActionsAvatarModalPopover
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}

      {/* button */}
      {status === 'loading' ? (
        <CircularProgress size={size} sx={{ color: 'white' }} />
      ) : (
        <IconButton onClick={handleClick} sx={{ p: 0 }}>
          <Avatar
            alt={user?.displayName || ''}
            src={user?.photoURL || ''}
            sx={{ width: size, height: size }}
          />
        </IconButton>
      )}
    </>
  );
}

NavbarActionsAvatar.defaultProps = {
  size: 30,
};
