import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import ButtonBase from '@mui/material/ButtonBase';

import { drawerWidth } from './drawer';

import { NavbarActionsAvatar } from '../../navbar/actions/avatar';
import { WebNavbarSearch } from './search';

import LogoIcon from '../../../../assets/images/logo/icon.svg';
import LogoText from '../../../../assets/images/logo/text-black.svg';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function WebNavbarAppBar({ drawerOpen, setDrawerOpen }: any) {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'grey.200',
        bgcolor: 'white',
      }}
      open={drawerOpen}
    >
      <Toolbar variant="dense">
        {/* drawer button */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerOpen(true)}
          edge="start"
          sx={{
            marginRight: 5,
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        {/* logo */}
        <ButtonBase
          sx={{ alignItems: 'flex-end', mr: { xs: 1, sm: 2, md: 3 } }}
          onClick={() => navigate('/')}
          disableRipple
        >
          <img
            src={LogoIcon}
            alt="logo icon"
            style={{ height: '30px', marginRight: '5px' }}
          />
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline-flex' } }}>
            <img
              src={LogoText}
              alt="logo text"
              style={{ height: '22px', opacity: 0.87 }}
            />
          </Box>
        </ButtonBase>

        {/* search */}
        <WebNavbarSearch />

        <Box sx={{ flexGrow: 1 }} />

        {/* desktop */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {/* avatar */}
          <NavbarActionsAvatar size={25} />
        </Box>

        {/* mobile */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          {/* avatar */}
          <NavbarActionsAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
