import { KBarSearch } from 'kbar';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export function KbarSearch() {
  return (
    <TextField
      InputProps={{
        inputComponent: KBarSearch,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
}
