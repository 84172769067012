import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { logError } from '../../../_lib/log-error';

import MicrosftIcon from '../../../../assets/images/icons/microsoft.svg';

export function AuthLoginMicrosoft() {
  const provider = new OAuthProvider('microsoft.com');

  const handleClick = () => {
    signInWithPopup(getAuth(), provider).catch((error) => {
      logError(error);
    });
  };

  return (
    <Box sx={{ color: '#2196F3' }}>
      <Button
        variant="outlined"
        fullWidth
        sx={{ textTransform: 'none' }}
        color="inherit"
        onClick={handleClick}
        startIcon={
          <img
            src={MicrosftIcon}
            alt="microsoft icon"
            style={{ marginRight: 8, height: '20px' }}
          />
        }
      >
        Sign in with Microsoft
      </Button>
    </Box>
  );
}
