import { CommonProviders } from '@taxonomy.cc/core/src/_lib/providers/common';
import { WebRouter } from '@taxonomy.cc/core/src/_screen/web/_router';

export function App() {
  return (
    <CommonProviders enableKbar>
      <WebRouter />
    </CommonProviders>
  );
}

export default App;
