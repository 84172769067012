import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { ImpressumCopyright, ImpressumPrivacy } from '../impressum';

export function WebFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        mt: 'auto',
      }}
    >
      <Divider sx={{ mb: 2.5 }} />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ justifyContent: 'space-between' }}
      >
        <ImpressumCopyright />
        <ImpressumPrivacy />
      </Stack>
    </Box>
  );
}
