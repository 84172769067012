import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { logError } from '../../../_lib/log-error';

import GoogleIcon from '../../../../assets/images/icons/google.svg';

export function AuthLoginGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleClick = () => {
    signInWithPopup(auth, provider).catch((error) => {
      logError(error);
    });
  };

  return (
    <Box sx={{ color: '#518EF8' }}>
      <Button
        variant="outlined"
        fullWidth
        sx={{ textTransform: 'none' }}
        color="inherit"
        onClick={handleClick}
        startIcon={
          <img
            src={GoogleIcon}
            alt="google icon"
            style={{ marginRight: 8, height: '20px' }}
          />
        }
      >
        Sign in with Google
      </Button>
    </Box>
  );
}
