import { useKBar } from 'kbar';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import SearchIcon from '@mui/icons-material/Search';

export function WebNavbarSearch() {
  const { query } = useKBar();
  return (
    <Box>
      <Button
        onClick={query.toggle}
        disableRipple
        sx={{
          textTransform: 'none',
          pr: { xs: 2, sm: 3, md: 4, lg: 5 },
          py: 0.5,
        }}
        startIcon={<SearchIcon sx={{ ml: 0 }} />}
      >
        Search...
      </Button>
    </Box>
  );
}
