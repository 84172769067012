import { KBarResults, useMatches } from 'kbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function KbarResultsDetail({ item, active }: any) {
  return (
    <ListItem disablePadding>
      <ListItemButton selected={active}>
        <ListItemText primary={typeof item === 'string' ? item : item.name} />
      </ListItemButton>
    </ListItem>
  );
}

export function KbarResults() {
  const { results } = useMatches();

  return (
    <List>
      <KBarResults items={results} onRender={KbarResultsDetail} />
    </List>
  );
}
