import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LayoutWeb } from '../../layout/web';

export function ScreenWebHome() {
  return (
    <LayoutWeb>
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Home
        </Typography>
      </Box>
    </LayoutWeb>
  );
}
